import React, { FC, Fragment } from "react";
import { SwiperSlide } from "swiper/react";
import VideoCard from "./cards/VideoCard";
import RenderProductCard from "./cards/RenderProductCard";
import RenderCollectionCard from "./cards/RenderCollectionCard";
import RenderAttributeCard from "./cards/RenderAttributeCard";
import RenderBannerCard from "./cards/RenderBannerCard";
import { FormattedDataType } from "@/templates/types";
import { SectionTypeEnum } from "@/generated/graphql";

interface SectionGeneratorProps {
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const SectionGenerator: FC<SectionGeneratorProps> = ({
  section,
  isSliderSection,
}) => {
  if (section.type === SectionTypeEnum.Video) {
    return section?.links?.map((link, index) =>
      isSliderSection ? (
        <SwiperSlide key={index} style={{ height: "auto" }}>
          <VideoCard
            link={link}
            section={section}
            isSliderSection={isSliderSection}
          />
        </SwiperSlide>
      ) : (
        <Fragment key={index}>
          <VideoCard
            link={link}
            section={section}
            isSliderSection={isSliderSection}
          />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.Banner) {
    return section?.banners?.map((banner) =>
      isSliderSection ? (
        <SwiperSlide
          key={banner?.id}
          style={{
            display: "flex",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RenderBannerCard banner={banner} section={section} isSliderSection />
        </SwiperSlide>
      ) : (
        <Fragment key={banner?.id}>
          <RenderBannerCard banner={banner} section={section} />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.AttributesRow) {
    return section?.attributeValues?.map((attributeValue) =>
      isSliderSection ? (
        <SwiperSlide
          key={attributeValue?.id}
          style={{
            display: "flex",
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RenderAttributeCard
            attributeValue={attributeValue}
            section={section}
            isSliderSection
          />
        </SwiperSlide>
      ) : (
        <Fragment key={attributeValue?.id}>
          <RenderAttributeCard
            attributeValue={attributeValue}
            section={section}
          />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.CollectionsRow) {
    return section?.collections?.map((collection) =>
      isSliderSection ? (
        <SwiperSlide
          style={{ display: "flex", height: "auto", justifyContent: "center" }}
          key={collection?.id}
        >
          <RenderCollectionCard
            collection={collection}
            section={section}
            isSliderSection
          />
        </SwiperSlide>
      ) : (
        <Fragment key={collection?.id}>
          <RenderCollectionCard collection={collection} section={section} />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.ProductsRow) {
    return section?.products?.map((product, index) =>
      isSliderSection ? (
        <SwiperSlide
          key={product?.id}
          style={{
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RenderProductCard
            index={index}
            product={product}
            section={section}
            isSliderSection
          />
        </SwiperSlide>
      ) : (
        <Fragment key={product?.id}>
          <RenderProductCard
            index={index}
            product={product}
            section={section}
          />
        </Fragment>
      )
    );
  }

  return null;
};

export default SectionGenerator;
