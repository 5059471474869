import SectionCardWrapper from "@/templates/black-and-white/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import CollectionCard from "./CollectionCard";

interface RenderCollectionCardProps {
  collection: FormattedDataType["collections"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderCollectionCard: React.FC<RenderCollectionCardProps> = ({
  collection,
  section,
  isSliderSection,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      isCollection
      data-test="collection-card"
      background={section?.background}
    >
      <CollectionCard
        collection={collection}
        hideTitle={!section?.showItemName}
      />
    </SectionCardWrapper>
  );
};

export default RenderCollectionCard;
