import React from "react";
import styled from "styled-components";
import { Photo } from "@/shared/globals/UiElements/Photo";
import { CollectionCardProps } from "../../../../types";
import { Link } from "@/i18n/i18n-navigation";

const IMAGE_SIZE = 125;

const CollectionCard: React.FC<CollectionCardProps> = ({
  collection,
  hideTitle,
}) => {
  return (
    <StyledLink fullHeight href={`/product/${collection?.handle}`}>
      <Photo
        src={collection?.image?.src || "/default-placeholder-image.png"}
        alt={collection?.title!}
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
      />
      {!hideTitle && <CollectionTitle>{collection?.title}</CollectionTitle>}
    </StyledLink>
  );
};

export default CollectionCard;

/**
 *
 * Styles
 *
 */

const StyledLink = styled(Link)`
  padding: 10px;
  height: ${IMAGE_SIZE}px;
  max-width: ${IMAGE_SIZE}px;
  box-sizing: content-box;
`;

const CollectionTitle = styled.div`
  margin-top: 12px;
  text-align: center;
`;
